import React, { useState } from 'react';
import { string } from 'prop-types';
import { navigate } from 'gatsby';
import { buildLogger, LoggableError } from 'logger';
import StackTrace from 'stacktrace-js';
import root from 'window-or-global';

import { LOYALTY_UPDATE_ROUTE } from 'constants/navigation';

import { useNoticationContext } from 'contexts/notification-context';
import { useStateContext } from 'contexts/state-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faBinoculars } from '@fortawesome/pro-light-svg-icons';

import {
  Grid, Hidden, Button,
  Typography, CircularProgress,
} from '@material-ui/core';

import useStyles from '../styles';

import LoyaltyIdInput from './loyalty-id-input';

const scrollToError = () => {
  const elmnt = root.document.getElementById('loyalty-id');
  elmnt.scrollIntoView({ behavior: 'smooth', inline: 'start' });
};

const LookUpForm = ({ passId }) => {
  const logger = buildLogger();
  const styles = useStyles();
  const [formData, setFormData] = useState({
    loyaltyId: '',
  });
  const [error, setError] = useState({
    hasError: false,
    fieldErrors: {
      loyaltyId: false,
    },
  });
  const [loading, setLoading] = useState(false);

  const noticationContext = useNoticationContext();
  const stateContext = useStateContext();

  const isValidForm = () => {
    const { loyaltyId } = formData;

    if (!loyaltyId) {
      setError({ ...error, hasError: true });
      scrollToError();
      return false;
    }

    setError({ ...error, hasError: false });
    return true;
  };

  const onLookup = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (isValidForm()) {
      try {
        setLoading(true);
        const { loyaltyId } = formData;
        const getLoyaltyService = await import('services/loyalty-service');
        const { getLoyaltyByPassLoyalty } = getLoyaltyService.default({
          stateContext,
          noticationContext,
        });
        const isLoyalty = await getLoyaltyByPassLoyalty({ loyaltyId, passId });
        if (isLoyalty) {
          navigate(`${LOYALTY_UPDATE_ROUTE}?passId=${passId}`);
        } else {
          setLoading(false);
        }
      } catch (err) {
        const trace = await StackTrace.get();
        const { stack = '', message = '' } = err;
        logger.error(new LoggableError({
          data: { stack, caller: 'QRCodeReader:onError' },
          message,
          trace,
        }));
        setLoading(false);
      }
    }
  };

  const { hasError, fieldErrors } = error;

  return (
        <form autoComplete="off" id="look-up-loyalty-card">
          <Grid spacing={2}
            container
            direction="row"
            justifyContent="center"
            alignItems="center">

            <Grid item xs={12} sm={10} className={styles.twoTopBottomMargin}>
              <Typography
                variant="h4"
                component="h3"
                color="inherit"
                align="center"
                className={styles.headerGreen}>
                Search by Id
                <FontAwesomeIcon icon={faBinoculars} size="lg" className={styles.headerIcon}/>
                <Typography
                  variant="caption"
                  component="div"
                  align="right"
                  className={styles.headerSubText}>
                    {'Id on back of Apple Wallet card or on the front of Google Pay'}
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={10} id="loyalty-id">
              <LoyaltyIdInput
                onUpdateParent={(loyaltyId) => {
                  setError({
                    hasError,
                    fieldErrors: {
                      ...fieldErrors,
                      loyaltyId: !loyaltyId,
                    },
                  });
                  setFormData({ loyaltyId });
                }}
                error={hasError}/>
            </Grid>

            <Grid item xs={12} sm={10} className={styles.noMargin}>
            <Hidden smUp>
              <Button
                variant="contained"
                color="primary"
                className={styles.formbutton}
                onClick={onLookup}
                type="submit"
                aria-label="find loyalty card by id"
                fullWidth
                disabled={loading}
                endIcon={loading
                  ? <CircularProgress className={styles.submitLoading}/>
                  : (
                    <FontAwesomeIcon icon={faSearch} className={styles.submitIcon}/>
                  )
                }
                >
               Search
             </Button>
            </Hidden>
            <Hidden xsDown>
              <Button
                variant="contained"
                color="primary"
                className={styles.formbutton}
                onClick={onLookup}
                type="submit"
                fullWidth
                aria-label="find loyalty card by id"
                disabled={loading}
                endIcon={loading
                  ? <CircularProgress className={styles.submitLoading}/>
                  : (
                    <FontAwesomeIcon icon={faSearch} className={styles.submitIcon}/>
                  )
                }
                >
               Find Loyalty Card
             </Button>
            </Hidden>
            </Grid>
            </Grid>
        </form>
  );
};

LookUpForm.propTypes = {
  passId: string.isRequired,
};

export default LookUpForm;
