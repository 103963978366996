import React, { useState } from 'react';
import { string } from 'prop-types';
import useStyles from 'apputil/view-styles';

import { Paper } from '@material-ui/core';
import LookUpForm from './look-up-form';

const ScanSearchView = ({ passId }) => {
  const styles = useStyles();
  const [showForm, setShowForm] = useState(true);

  return (
      <Paper className={styles.paper} elevation={4}>
        <LookUpForm
          showLoyalty={() => setShowForm(!showForm)}
          passId={passId}
        />
    </Paper>
  );
};

ScanSearchView.propTypes = {
  passId: string.isRequired,
};

export default ScanSearchView;
