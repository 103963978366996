import React, { useState } from 'react';
import { func, bool } from 'prop-types';
import { LOYALTY_CARD_SERIAL } from 'constants/font-awesome';

import AdornmentIcon from 'components/addmornment-icon';

import { TextField, InputAdornment } from '@material-ui/core';

const alphanumeric = RegExp(/^[a-zA-Z0-9 -_]*$/);

const LoyaltyIdInput = ({ error, onUpdateParent }) => {
  const [fieldError, setFieldError] = useState(false);
  const [fieldValue, setFieldValue] = useState('');

  return <TextField
            required
            type="text"
            label="Loyalty ID"
            name="Loyalty-id"
            fullWidth
            margin="normal"
            variant="outlined"
            autoComplete="off"
            aria-label="loyalty card ID"
            onChange={(e) => {
              const { target: { value = '' } } = e;
              const trimmed = value.trim();
              const valid = alphanumeric.test(trimmed);
              setFieldError(!valid);
              setFieldValue(trimmed);
              onUpdateParent(trimmed);
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{
                <AdornmentIcon
                  error={fieldError || (error && fieldValue === '')}
                  valid={fieldValue !== ''}
                  type={LOYALTY_CARD_SERIAL}
                  />
              }</InputAdornment>,
            }}
            inputProps={{ maxLength: 100 }}
            error={fieldError || (error && fieldValue === '')}
            helperText={fieldError || (error && fieldValue === '')
              ? 'This field is required.  Loyalty ID is on the back of a Apple Wallet Card or the front of a Google Pay card'
              : 'The Loyalty ID for the card you want to manage.'
            }
               />;
};

LoyaltyIdInput.propTypes = {
  error: bool.isRequired,
  onUpdateParent: func.isRequired,
};

export default LoyaltyIdInput;
